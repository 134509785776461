.dashboard-icon-1 {
  svg {
    color: rgba(126,123,242,1);
    //border-radius: 50%;
    //background: linear-gradient(90deg, rgba(126,123,242,1) 50%, rgba(7,0,255,0.7035189075630253) 100%);
  }
}

.dashboard-icon-2 {
  svg {
    color: rgba(142,153,171,1);
  }
}

.dashboard-icon-3 {
  svg {
    color: rgba(242,101,34,1);
  }
}