.charge-description-list {
  li:not(:nth-child(6), :nth-child(13), :nth-child(20)) {
    border-right: 1px solid #E2E8F0;
  }
}

@media (max-width: 480px) {
  .charge-description-list {
    li {
      border: none !important;
    }
  }
}