.sidebar:hover .icon {
  color: #114B8F;
}

.sidebar:hover .title {
  color: #2D3748;
  font-weight: 700;
}

.sidebar-menu-item-container {
  border-bottom: 1px solid #F7F8F9;

  &:last-child{
    border-bottom: 0 !important;
  }
}